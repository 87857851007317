import React, { ComponentType, ReactNode } from 'react'
import { Form as FFForm } from 'react-final-form'
import { UiComponent } from '@/components/ui/types'

type FormProps = {
  onSubmit: (v: any) => void
  validate?: (v: any) => any
  initialValues?: { [key: string]: any }
  clearOnSubmit?: boolean
  onChange?: (values: any) => void
  children: ReactNode
} & UiComponent

const Form: ComponentType<FormProps> = ({
  onSubmit,
  validate,
  children,
  initialValues,
  clearOnSubmit = false,
  onChange,
  className = ''
}) => {
  return (
    <FFForm
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      subscription={{
        // Only subscribe to what we need to avoid unnecessary re-renders
        values: !!onChange, // Only subscribe to values if we have an onChange handler
        submitError: true
      }}
      render={({ handleSubmit, submitError, values }) => {
        // Call onChange when values change - memoize with useRef to avoid re-renders
        const prevValuesRef = React.useRef(values)
        React.useEffect(() => {
          // Only call onChange if values have actually changed
          if (
            onChange &&
            JSON.stringify(prevValuesRef.current) !== JSON.stringify(values)
          ) {
            prevValuesRef.current = values
            onChange(values)
          }
        }, [values, onChange])

        return (
          <form
            onSubmit={(v) => {
              // TODO @Jakub improve
              if (clearOnSubmit) {
                // @ts-ignore
                return handleSubmit(v).then(() => clearOnSubmit && form.reset())
              } else return handleSubmit(v)
            }}
            className={`${className} form`}
          >
            {submitError && <div className="error">{submitError}</div>}
            {children}
          </form>
        )
      }}
    />
  )
}

export default Form
