import React, { ComponentType } from 'react'
import { Loader } from 'react-feather'
import { UiComponent } from '@/components/ui/types'

type SpinnerProps = { loading: boolean } & UiComponent

export const Spinner: ComponentType<SpinnerProps> = ({
  loading,
  className = ''
}) => (loading ? <Loader className={`animate-spin-slow ${className}`} /> : null)
